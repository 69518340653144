import { GetStaticProps } from 'next'
import React from 'react'

import Page from '@aletheia/common/components/Page'
import { preCacheApolloQueries } from '@aletheia/common/lib/apollo'
import {
  GetOrganizationsDocument,
  GetPaymentStatsAggregateDocument,
} from '@aletheia/graphql'

import {
  HeroUnit,
  OurFunds,
  GetFundedList,
  FundManagers,
  PartnerList,
} from '../components/Home'
import { AlertBox } from '../components/Home/AlertBox'
import {
  fetchFunds,
  IFund,
  IPerson,
  fetchSeriesEntries,
  fetchPage,
  IPage,
} from '../contentful'

type HomeProps = {
  Alert: IPage | null
  Funds: IFund[]
  FundManagerTeam: IPerson[]
}

const Home: React.FC<HomeProps> = ({ Alert, Funds, FundManagerTeam }) => {
  const baseUrl =
    (process.env.VERCEL_URL && `https://${process.env.VERCEL_URL}`) ||
    process.env.NEXT_PUBLIC_BASE_URL

  return (
    <Page
      title="Effective Altruism Funds"
      variant="full"
      showTitle={false}
      showPageTitle={false}
      ogImage={`${baseUrl}/landing/og-image.jpg`}
      description={'We fund outstanding projects focused on social impact'}
    >
      <AlertBox Alert={Alert} />
      <HeroUnit />
      <GetFundedList />
      <OurFunds Funds={Funds} />
      <FundManagers FundManagers={FundManagerTeam} />
      <PartnerList />
    </Page>
  )
}

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  const initialApolloState = await preCacheApolloQueries([
    GetOrganizationsDocument,
    GetPaymentStatsAggregateDocument,
  ])

  const [Alert, Funds, FundManagerTeam] = await Promise.all([
    fetchPage('homepage-alert'),
    fetchFunds(),
    fetchSeriesEntries('homepage-fund-managers'),
  ])

  if (!FundManagerTeam) throw new Error('Missing Homepage Fund Managers')

  return {
    props: {
      Alert: Alert || null,
      Funds,
      FundManagerTeam,
      initialApolloState,
    },
    revalidate: 20,
  }
}

export default Home
