import { Container, Typography } from '@material-ui/core'
import React from 'react'

import { IPage } from '../../contentful'
import Markdown from '../Markdown'

export const AlertBox: React.FC<{ Alert: IPage | null }> = ({ Alert }) => {
  if (!Alert) {
    return <></>
  }

  return (
    <div
      style={{
        borderBottom: '4px solid #efeccf',
        padding: '1.5em 2em 0.5em',
        background: '#fffce1',
        borderRadius: '0.25em',
      }}
    >
      <Container>
        {Alert.fields.title && (
          <Typography variant="h5" gutterBottom>
            {Alert.fields.title}
          </Typography>
        )}
        <Markdown>{Alert.fields.body}</Markdown>
      </Container>
    </div>
  )
}
